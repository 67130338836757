@import ../../../../styles/helpers

.item
    padding: 64px 32px
    border-radius: 20px
    background: $n8
    transition: box-shadow .2s
    +m
        padding: 64px 24px
    +dark
        background: $n2
    &:hover
        box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1)

.icon
    display: flex
    align-items: center
    height: 48px
    margin-bottom: 32px
    +m
        margin-bottom: 24px
    img
        max-height: 100%

.title
    margin-bottom: 16px
    +body-bold-2

.content
    color: $n4