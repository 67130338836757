@import ../../styles/helpers

.inner
    overflow: hidden
    margin: 25px auto
    @import ../../styles/helpers

.item
    display: none
    margin: auto
    width: calc(40% - 40px)
    position: relative
    &:first-child
        display: block

.title
    text-align: center

.info
    margin-top: 12px
    text-align: center
    +body-2
    color: $n4

.btns
    display: flex
    justify-content: center
    margin-top: 32px
    .button
        &:not(:last-child)
            margin-right: 12px

.note
    margin-top: 32px
    text-align: center
    +caption-2
    color: $n4

.passwordText
    margin-top: 20px
    text-align: center
    +caption-2
    color: $n4

.form
    margin-top: 32px
    position: relative
    & > .button
        width: 100%
        margin-top: 12px

.foot
    margin: 32px 0
    text-align: center
    +caption-2
    color: $n3
    +dark
        color: $n6

.link,
.password
    font-weight: 600
    transition: color .2s

.link
    color: $p1
    &:hover
        color: darken($p1, 10)

.password
    color: $n3
    &:hover
        color: $n1
    +dark
        color: $n6
        &:hover
            color: $p1

.variants
    display: flex
    flex-direction: column
    margin-bottom: 20px

.radio
    &:not(:last-child)
        margin-bottom: 16px
        padding-bottom: 16px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.code
    display: flex
    justify-content: center

.number
    flex-shrink: 0
    width: 48px
    input
        width: 100%
        height: 64px
        border-radius: 8px
        border: 1px solid $n7
        background: $n7
        text-align: center
        +dm-sans
        font-size: 32px
        font-weight: 600
        color: $n2
        transition: all .2s
        &:focus
            border-color: $n6
            background: $n8
        +dark
            background: $n2
            border-color: $n2
            color: $n8
            &:focus
                background: none
                border-color: $n3
    &:not(:last-child)
        margin-right: 16px

.spacing
    margin: auto
    width: 70%
    transition: width 0.3s ease-in-out

.inputRowBox
    margin: 30px 0

.forgotPassword
    text-align: right
    margin-top: 8px

.link
    color: #007bff
    font-size: 14px
    text-decoration: none
    &:hover
        text-decoration: underline

.button
    width: 300px

.inputRowBox
    position: relative
    margin-bottom: 16px

.eyeIconBase
    position: absolute
    right: 10px
    transform: translateY(-50%)
    cursor: pointer
    color: #888

.eyeIcon46
    @extend .eyeIconBase
    top: 46%

.eyeIcon65
    @extend .eyeIconBase
    top: 65%


