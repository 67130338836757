@import ../../../styles/helpers

.section
    +d
        margin-bottom: 40px

.container
    max-width: 1440px
    +w
        padding: 0 40px
    +t
        padding: 0
    +m
        padding: 0 8px

.preview
    position: relative
    overflow: hidden
    border-radius: 24px
    +t
        border-radius: 0
    +m
        border-radius: 16px 16px 0 0
    &:before
        content: ""
        display: block
        padding-bottom: 46%
        +m
            display: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 101%
        object-fit: cover
        +m
            position: static
            height: 500px
            object-position: 50% 100%
    
.wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 164px 80px
    text-align: center
    +d
        padding: 116px 80px
    +t
        padding: 90px 64px
    +m
        padding: 64px 24px 

.title
    margin-bottom: 16px
    +dark
        color: $n2

.info
    margin-bottom: 24px
    color: $n2

.panel
    margin: -90px 80px 0
    +w
        margin: -90px 40px 0
    +d
        margin-top: -60px
    +m
        margin: -100px 8px 0

.row
    display: flex
    +x
        margin: 0 -20px
    +d
        margin-top: -8px
    +t
        margin: 0
    +m
        display: block
        margin: -16px 0 -8px
    & > div
        &:first-child
            flex: 0 0 304px
            +x
                flex: 0 0 270px
            +t
                flex: 0 0 180px
        &:nth-child(2)
            flex-grow: 1
        &:nth-child(3)
            flex: 0 0 296px
            +x
                flex: 0 0 280px
            +d
                flex: 0 0 240px
            +t
                flex: 0 0 170px