@import ../../../styles/helpers

.item
    display: block
    overflow: hidden
    border-radius: 24px
    border: 1px solid $n6
    text-align: center
    color: $n2
    transition: border-color .2s
    +dark
        background: $n2
        border-color: $n3
        color: $n8
    &:hover
        border-color: $n5
        .preview
            img
                transform: scale(1.1)

.preview
    position: relative
    overflow: hidden
    &:before
        content: ""
        display: block
        padding-bottom: 77%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        transition: transform 1s

.rating
    position: absolute
    top: 16px
    left: 16px
    z-index: 2
    display: flex
    justify-content: center
    align-items: center
    min-width: 50px
    height: 24px
    background: $n8
    border-radius: 12px
    +caption-bold-2
    +dark
        background: $n2
        color: $n8
    svg
        margin-right: 4px
        fill: $s3
    
.body
    padding: 0 16px 24px

.avatar
    position: relative
    z-index: 3
    width: 80px
    height: 80px
    border-radius: 50%
    overflow: hidden
    box-shadow: 0 0 0 2px $n8
    margin: -40px auto 16px
    +dark
        box-shadow: 0 0 0 2px $n2
    img
        width: 100%
        height: 100%
        transform: scale(1.02)
        border-radius: 50%
    
.title
    margin-bottom: 4px
    +body-bold-2

.location
    +caption-2
    color: $n4