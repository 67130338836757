@import ../../../styles/helpers

.head
    display: flex
    align-items: flex-end
    margin-bottom: 80px
    +d
        margin-bottom: 64px
    +m
        display: block
        margin-bottom: 32px

.details
    max-width: 540px
    margin-right: auto

.button
    +m
        width: 100%
        margin-top: 32px

.preview
    position: relative
    margin: 0 -80px
    border-radius: 16px
    overflow: hidden
    +x
        margin: 0 -40px
    +t
        border-radius: 0
    +m
        margin: 0 -32px
        &:before
            content: ""
            display: block
            padding-bottom: 65%
    img
        width: 100%
        +m
            position: absolute
            top: 0
            left: 0
            height: 100%
            object-fit: cover